<template>
  <el-container style="background-color: #f5f5f5">
    <el-header class="home_header">
      <el-row type="flex" justify="space-between"
        ><el-col :span="2"
          ><div class="home_header_logo">
            <el-image
              style="width: 80px; height: 100%"
              :src="require('@/assets/images/logo/header.png')"
              :fit="contain"
            ></el-image></div></el-col
        ><el-col :span="22"
          ><el-menu
            :default-active="$route.path"
            router
            mode="horizontal"
            @select="handleSelect"
            background-color="rgba(255,255,255,0)"
            text-color="#fff"
            active-text-color="#ffd04b"
            class="menu"
          >
            <el-row type="flex" :gutter="65" justify="center"
              ><el-col :span="4">
                <el-menu-item index="/home">Homepage</el-menu-item></el-col
              ><el-col :span="4">
                <el-menu-item index="/database">Molecules</el-menu-item></el-col
              ><el-col :span="5"
                ><el-menu-item index="/networkModel"
                  >Networks/Data Sets</el-menu-item
                ></el-col
              ><el-col :span="4">
                <el-menu-item index="/article"
                  >Papers & News</el-menu-item
                ></el-col
              >
              <el-col :span="4"
                ><el-menu-item index="/getAnAccount"
                  >Get An Account</el-menu-item
                ></el-col
              ></el-row
            >
          </el-menu></el-col
        ><el-col :span="2"
          ><div class="home_header_user_info">
            <el-link
              class="home_header_user_info_link"
              icon="el-icon-user"
              :underline="false"
              v-if="userName == ''"
              @click="login"
              >Login</el-link
            >
            <el-tooltip  class="item" effect="light" content="click to logout" v-else placement="bottom">
            <el-link
              class="home_header_user_info_link"
              icon="el-icon-user"
              :underline="false"
              
              @click="loginOut"
              >{{ userName }}</el-link
            >
          </el-tooltip>
          </div></el-col
        ></el-row
      >
    </el-header>
    <el-main class="main"><router-view /></el-main>
    <el-footer class="home_footer">
      <div class="home_footer_copyright">
        <span>Powered by LabMolDECAR</span>
      </div>
    </el-footer>
  </el-container>
</template>
  
  <script>
export default {
  data() {
    return {
      activeIndex: "1",
      userName: "",
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    //获取用户信息
    getUserInfo() {
      if (this.$store.state.userObj) {
        this.userName = this.$store.state.userObj.name;
      }
    },
    handleSelect(key, keyPath) {},
    login() {
      this.$message.info("Please log in");
      this.$store.dispatch("user/loginOut").then((res) => {
        if (res) {
          {
            this.$router.push("/login");
          }
        }
      });
    },
    loginOut() {
      this.$store.dispatch("user/loginOut").then((res) => {
        if (res) {
          {
            this.$message.warning("Logout successful!");
            this.$router.push("/login");
          }
        }
      });
    },
  },
};
</script>
  <style scoped>
.home_header {
  position: fixed;
  width: 100%;
  height: 0px;
  z-index: 10;
  background-color: #172A4B;
}
.el-menu {
  padding: 0;
}
.el-menu-item {
  text-align: center;
  font-size: 20px !important;
}
.home_header_logo {
  margin-top: 1vh;
  margin-left: 1vw;
  font-size: 1vh;
  color: #fff;
}
.menu {
  line-height: 20px;
}
.main {
  padding: 0;
}
.home_header_user_info {
  margin-top: 20px;
  margin-left: 35px;
}
.home_header_user_info_link {
  font-size: 20px;
  color: #fff;
}
.el-dropdown-link {
  color: #fff;
  font-size: 2vh;
}
.home_footer {
  display: flex;
  height: 10vh !important;
  background-color: #304156;
  color: #fff;
}

.home_footer_copyright {
  margin: auto;
}
</style>
  